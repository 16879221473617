import React from 'react';
import '../css/index.css';

const Footer = () => {
    return (
        <footer>
            <h3>Created by Franco Juri ❤</h3>
        </footer>
    )
}

export default Footer;
